import React from "react";

const Footer = () => {
  return (
      <div id="footer" className="jumbotron">
        <div id="email">
          <a href="mailto:donatella@donatellasignorini.com" className="link">
            donatella@donatellasignorini.com
          </a>
        </div>
      </div>
  );
};

export default Footer;
