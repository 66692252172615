import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import slider from "./sliderH.json";
import sliderSM from "./sliderHsm.json";

const Slider = () => {
  return (
    <div className="container-fluid">
      <Carousel
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        infiniteLoop
        autoPlay
        emulateTouch
        // width="100%"
        dynamicHeight={true}
      >
        {slider.map((item, index) => {
          if (item.width === "") {
            return (
              <div key={"S" + index}>
                <img src={item.image} alt={item.title} className="img-fluid"/>
                <p className="legend">{item.title}</p>
              </div>
            );
          } else {
            return (
              <div key={"S" + index}>
                <img
                  src={item.image}
                  alt={item.title}
                  style={{ width: 450, height: 650 }}
                />
                <p className="legend">{item.title}</p>
              </div>
            );
          }
        })}
      </Carousel>
      <br />
    </div>
  );
};

const SliderSM = () => {
  return (
    <div className="container-fluid">
      <Carousel
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        infiniteLoop
        autoPlay
        emulateTouch
        // width="100%"
        // dynamicHeight={true}
      >
        {sliderSM.map((item, index) => {
          return (
            <div key={"S" + index}>
              <img src={item.image} alt={item.title} className="img-fluid"/>
              <p className="legend">{item.title}</p>
            </div>
          );
        })}
      </Carousel>
      <br />
    </div>
  );
};

const PageText = () => {
  return (
    <div className="text-responsive">
      <p>
        Donatella Signorini was born in Florence, Italy into a family with a
        rich and varied artistic heritage, which continues to inspire her
        interest in fashion and art.
      </p>

      <p>
        Donatella's father Giampaolo Signorini was a banker who also worked as a
        translator for Sotheby's. Her mother Giovanna Pracchia was an art
        teacher and painter who also sculpted beautiful works of art from rough
        wood. Her grandmother Roberta Signorini was also an art teacher and
        painter.
      </p>

      <p>
        Donatella's great uncle was Telemaco Signorini (1835-1901). Born in
        Florence, he was one of the most eminent and influential artists of his
        time and belonged to the group known as "I Macchiaioli". Telemaco's
        father was Giovanni Signorini who was a court painter for the Grand Duke
        of Tuscany.
      </p>

      <p>
        Donatella's aunt, Paola Signorini, was a leading designer of evening
        wear in the 60's and 70's. Her atelier was in Piazza Santa Trinita in
        the heart of Florence. She employed a young Gianni Versace as a stylist
        and was a personal friend of the Ferragamo and Gucci families.
      </p>

      <p>
        Growing up in Florence, Donatella idolised her aunt and in school
        holidays would help out behind the scenes at her Aunt's fashion shows at
        the Palazzo Pitti in Florence, dressing the models before they went onto
        the catwalk.
      </p>

      <p>
        Now, on her regular buying trips to Florence, Donatella always remembers
        how her aunt used to choose the very best fabrics and accessories for
        her collections and is proud to continue her family's best traditions.
      </p>
    </div>
  );
};

const Heritage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div className="container-fluid">
      <div className="row page-row">
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-center">
          <span className="hidden-md hidden-sm hidden-xs">
            <Slider />
          </span>
          <span className="hidden-lg hidden-xl">
            <SliderSM />
          </span>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-left">
          <PageText />
        </div>
      </div>
    </div>
  );
};

export default Heritage;
