import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import "./styles/hamburger.scss";
import "./styles/carousel.css"; // change this to the file path of your overrides
import "./styles/imports.scss";
import "./styles/bootstrap.min.css";
import "./styles/main.css";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Index from "./pages/Index";
import Heritage from "./pages/Heritage";
import Anniversary from "./pages/Anniversary";
import Contact from "./pages/Contact";

const App = () => {
  return (
    <div id="page">
      <Header />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="Heritage" element={<Heritage />} />
        <Route path="Anniversary" element={<Anniversary />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
