import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div>
      <div id="title" className="container-fluid">
        <div className="hidden-lg hidden-lg hidden-md text-left">
          {/* <HamburgerMenu /> */}
        </div>
        <Link to="Index">
          <img
            className="img-fluid"
            src="/layout/logo.png"
            title="Donatella Signorini"
            alt="Logo Donatella Signorini"
            width="500"
            height="300"
          />
          {/* <h1>Donatella Signorini</h1> */}
        </Link>
      </div>
      <div id="menu">
        <div className="hidden-sm hidden-xs">
          <Link to="Index" className="Introduction">
            Introduction
          </Link>
          <Link to="Heritage" className="Heritage">
            Heritage
          </Link>
          <Link to="Anniversary" className="Anniversary">
            35th Anniversary
          </Link>
          <Link to="Contact" className="Contact">
            Contact
          </Link>
        </div>
      </div>
      <div className="hidden-lg hidden-lg hidden-md">
        <div className="fixed-top drop text-left">
          <div className="dropdown">
            <button type="button" data-toggle="dropdown">
              <svg
                className="w-8 h-8 text-gray-500"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
              MORE
            </button>

            <div className="dropdown-menu">
              <Link to="Index" className="dropdown-item">
                Introduction
              </Link>
              <Link to="Heritage" className="dropdown-item">
                Heritage
              </Link>
              <Link to="Anniversary" className="dropdown-item">
                Anniversary
              </Link>
              <Link to="Contact" className="dropdown-item">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
