import React, { useEffect } from "react";

const PageImage = () => {
  return (
    <div>
      <img
        src="images/018.jpg"
        className="img-fluid"
        alt=""
        width="600"
        height="345"
      />
      <br />
      <br />
      <br />
    </div>
  );
};

const PageText = () => {
  return (
    <div className="text-responsive">
      <h2>Contemporary Italian Womenswear</h2>

      <h3>By Appointment</h3>

      <p>
        Donatella Signorini has established, over the past 34 years, a unique
        consultancy sourcing clothes and accessories from Italy for discerning
        clients in London.
      </p>

      <p>
        Cultivating a rich network of designers from her native Florence and
        Milan, Donatella provides a personal link between designer and client
        that larger boutiques and department stores cannot match.
      </p>

      <p>
        She develops a deep understanding of each client's personal taste and
        character, enabling her to offer genuine advice on colour, style and
        co-ordination.
      </p>

      <p>
        With an emphasis on integrity, her key motivations are elegance, quality
        and attention to detail.
      </p>

      <p>
        In her Chelsea studio Donatella has created a unique environment where
        clients can discuss and try clothes in relaxed and tasteful
        surroundings. Her collection of Modern Art, ceramics and textiles
        provides additional inspiration.
      </p>

      <p>
        Donatella frequently works with leading stylists to co-ordinate
        wardrobes for business leaders, politicians, media and sports
        personalities.
      </p>
    </div>
  );
};

const Index = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div className="container-fluid">
      <div className="row page-row">
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-center">
          <PageImage />
        </div>

        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-left">
          <PageText />
        </div>
      </div>
    </div>
  );
};

export default Index;
