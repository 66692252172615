import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import slider from "./sliderC.json";

const Slider = () => {
  return (
    <div className="container-fluid">
      <Carousel
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        infiniteLoop
        autoPlay
        emulateTouch
        // width="100%"
        // dynamicHeight={true}
      >
        {slider.map((item, index) => {
          return (
            <div key={"S" + index}>
              <img src={item.image} alt={item.title} className="img-fluid" />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

const SliderX = () => {
  return (
    <div className="container-fluid">
      <Carousel
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        infiniteLoop
        autoPlay
        emulateTouch
        // width="80%"
        dynamicHeight={true}
      >
        {slider.map((item, index) => {
          return (
            <div key={"S" + index}>
              <img src={item.image} alt={item.title} style={{ width: "80%" }} />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

const PageText = () => {
  return (
    <div className="text-responsive">
      <p>Please email to make an enquiry or book an appointment:</p>

      <p>
        <a href="mailto:donatella@donatellasignorini.com" className="link">
          donatella@donatellasignorini.com
        </a>
      </p>

      <p>Subscribe to Mailing List</p>

      <p>
        Please enter your name and email address to receive news updates by
        email
      </p>

      <form
        action="http://richardwilding.createsend.com/t/r/s/nllldt/"
        method="post"
        id="subForm"
      >
        <label for="name">Name:</label>
        <input type="text" name="cm-name" id="name" className="name" />

        <label for="nllldt-nllldt">Email:</label>
        <input
          type="text"
          name="cm-nllldt-nllldt"
          id="nllldt-nllldt"
          className="email"
        />

        <input type="submit" value="Subscribe" className="button" />
      </form>

      <p>
        Website by{" "}
        <a
          href="http://www.richardwilding.com"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Richard Wilding
        </a>
      </p>
      <p>
        Adapted by{" "}
        <a
          href="http://www.resolution.pt"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Resolution
        </a>
      </p>
      <p>Lookbook photographs by Bruno Manetti</p>
    </div>
  );
};

const Contact = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div className="container-fluid">
      <div className="row page-row">
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-center">
        <span className="hidden-md hidden-sm hidden-xs">
            <Slider />
          </span>
          <span className="hidden-lg hidden-xl">
            <SliderX />
          </span>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-left">
          <span className="hidden-lg=true hidden-md=true">
            <br />
            <br />
          </span>
          <PageText />
        </div>
      </div>
    </div>
  );
};

export default Contact;
