import React, { useEffect } from "react";

const Anniversary = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <img
            src="images/letter.png"
            className="img-fluid"
            alt="letter"
          />
        </div>
      </div>
    </div>
  );
};

export default Anniversary;
